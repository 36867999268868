import { FC, useEffect, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AutocompleteSdk, User, UserSdk } from 'invictus-sdk-typescript'

import { DEFAULT_LOCALE } from '@Constants/locale'
import { SdkProvider } from '@Contexts/sdk/SdkContext'
import { useAllMessages } from '@Hooks/i18n'
import { lightTheme } from '@Material/theme'
import Locale from '@Types/locale'
import Market from '@Types/market'
import { formatToClientMarket } from '@Utils/market'
import { getDeviceSettings } from '@Utils/user'

import { getWidgetFrontendApiConfiguration } from '../config'
import { prefetchSearch } from '../prefetch/prefetch'

import { AutocompleteWidgetHelpers, AutocompleteWidgetInit } from './Autocomplete.types'
import AutocompleteWidget, { AutocompleteWidgetProps } from './AutocompleteWidget'

export type AutocompleteAppProps = AutocompleteWidgetProps & {
  locale?: Locale
}

const getAutocompleteSDK = (clientMarket: Market): AutocompleteSdk =>
  AutocompleteSdk.init(getWidgetFrontendApiConfiguration(), new UserSdk(new User(getDeviceSettings({ clientMarket }))))

const queryClient = new QueryClient()

const AutocompleteApp: FC<AutocompleteAppProps> = ({ locale = DEFAULT_LOCALE, ...rest }) => {
  const { messages } = useAllMessages(locale)

  useEffect(() => prefetchSearch())

  const autocompleteSdk = useMemo(() => getAutocompleteSDK(formatToClientMarket(locale)), [locale])

  if (!messages) {
    return null
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
          <SdkProvider customSdk={{ autocompleteSdk }}>
            <AutocompleteWidget {...rest} />
          </SdkProvider>
        </IntlProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export const init: AutocompleteWidgetInit = (
  containerId,
  inputId,
  eventName,
  locale,
  onError,
  shouldReturnSuggestions
) => {
  const input = document.getElementById(inputId)

  if (!(input instanceof HTMLInputElement)) {
    throw new Error(`${inputId} must refer to an "input" element`)
  }

  const root = createRoot(document.getElementById(containerId) as HTMLElement)
  root.render(
    <AutocompleteApp
      input={input}
      eventName={eventName}
      locale={locale}
      onError={onError}
      shouldReturnSuggestions={shouldReturnSuggestions}
    />
  )
}

export const helpers: AutocompleteWidgetHelpers = {
  stackedValue: '',
  setInputValue: (value) => {
    helpers.stackedValue = value
  },
}
