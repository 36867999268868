import { FC, HTMLAttributes } from 'react'
import { AutocompleteExample } from 'invictus-sdk-typescript'

import TagTuto from './TagTuto'
import * as styles from './styles'

type TagsTutoProps = {
  tags: AutocompleteExample[]
}

const TagsTuto: FC<TagsTutoProps & HTMLAttributes<HTMLUListElement>> = ({ tags, ...rest }) => (
  <ul {...rest} role="list" css={styles.tagsTuto}>
    {tags.map(({ label, accessibilityLabel }) => (
      <li key={label}>
        <TagTuto aria-label={accessibilityLabel} key={label} text={label} />
      </li>
    ))}
  </ul>
)

export default TagsTuto
