import { FC, RefObject } from 'react'
import { useTheme } from '@mui/material/styles'
import { IconId } from 'invictus-sdk-typescript'

import Tag, { TagProps } from '@DS/components/components/tag/Tag'
import Button, { ButtonProps } from '@DS/components/forms/button/Button'
import { getDsIcon } from '@DS/components/icons/dsIcons'

import * as styles from './styles'

export type TagButtonProps = Pick<TagProps, 'children' | 'size'> & {
  id: string
  role: string
  iconId?: IconId
  onClick: VoidFunction
  onHover: VoidFunction
  isHighlighted?: boolean
  itemRef?: RefObject<HTMLDivElement> & Omit<ButtonProps, 'itemRef'>
}

const TagButton: FC<TagButtonProps> = ({
  children,
  iconId,
  onClick,
  onHover,
  size,
  isHighlighted = false,
  itemRef,
  ...rest
}) => {
  const theme = useTheme()
  const Icon = getDsIcon(iconId)
  const TIPS_VARIANT = 'tips'

  return (
    <Button
      tabIndex={-1}
      css={styles.tagButton(theme, TIPS_VARIANT, isHighlighted)}
      onClick={onClick}
      onMouseOver={onHover}
      {...rest}
    >
      <Tag ref={itemRef} isOnButton mode={{ isOnHighlightCard: false, variant: TIPS_VARIANT }} icon={Icon} size={size}>
        {children}
      </Tag>
    </Button>
  )
}

export default TagButton
