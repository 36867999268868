import { FC, RefObject } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AutocompleteFullTextSuggestion } from 'invictus-sdk-typescript'

import Carousel from '@DS/components/interactions/carousel/Carousel'
import VisuallyHiddenLabel from '@DS/components/utils/aria/VisuallyHiddenLabel'
import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'
import { useIsMediumUp } from '@Hooks/useResponsive'

import TagButton from './TagButton'
import * as styles from './styles'

type FullTextSuggestionsSectionProps = {
  items: AutocompleteFullTextSuggestion[]
  onSelectItem?: (item: AutocompleteFullTextSuggestion) => void
  onItemOver?: (item: AutocompleteFullTextSuggestion) => void
  title?: string
  highlighted?: AutocompleteFullTextSuggestion
  itemRef: RefObject<HTMLDivElement>
}

const FullTextSuggestionsSection: FC<FullTextSuggestionsSectionProps> = ({
  title,
  items,
  onSelectItem,
  onItemOver,
  highlighted,
  itemRef,
}) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const isMediumUp = useIsMediumUp(theme)

  return (
    <div css={styles.fullTextSuggestionsSection(theme)} data-test="fullTextSuggestions">
      {title && (
        <Typography variant="subtitle1" color="textSecondary" css={styles.fullTextSuggestionsTitle(theme)}>
          {title}
        </Typography>
      )}
      <ConditionalWrapper
        condition={isMediumUp}
        wrapper={(children) => (
          <div tabIndex={-1} css={styles.fullTextSuggestionsSections(theme)}>
            {children}
          </div>
        )}
        defaultWrapper={(children) => (
          <Carousel itemsGap={1} spacingAround={0} tabIndex={-1}>
            {children}
          </Carousel>
        )}
      >
        {items.map((item, index) => {
          const { id, label, iconId } = item
          const isHighlighted = highlighted === item

          return (
            <TagButton
              data-cs-override-id={`search_suggestions-blue_tag-${index + 1}`}
              data-cs-mask
              id={id}
              key={label}
              role="option"
              itemRef={isHighlighted ? itemRef : undefined}
              iconId={iconId}
              onClick={() => onSelectItem?.(item)}
              onHover={() => onItemOver?.(item)}
              css={styles.fullTextSuggestionsTagButtons(theme)}
              isHighlighted={isHighlighted}
              aria-selected={isHighlighted}
            >
              <VisuallyHiddenLabel component="span" label={`${formatMessage({ id: 'suggestion' })} : `} />
              {label}
            </TagButton>
          )
        })}
      </ConditionalWrapper>
    </div>
  )
}

export default FullTextSuggestionsSection
