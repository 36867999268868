import axios, { type AxiosInstance } from 'axios'

import { type CatalogTerRenewalBookingRequest } from './generated/model/catalog-ter-renewal-booking-request'
import { AbstractSdk, type AbstractSdkConstructorParameters, type ControllerApiConstructor } from './abstract'
import { AuthenticationSdk } from './authentication'
import { Catch } from './catchError'
import { finalizationDonationsFromCart, finalizationInsuranceFromTripCart } from './finalize'
import {
  type AddCompanionFromContactRequest,
  type AddCompanionFromContactSuccess,
  BookingControllerApi,
  type Cart,
  type CartResetTravelAndRenewSearchRequest,
  type CartResetTravelAndRenewSearchSuccess,
  type CartUpdateRequest,
  type CatalogDiscountCardBookingRequest,
  type CatalogTerBookingRequest,
  type Configuration,
  type ConnectedTravelerDiscountCardBookingRequest,
  type ContactCompanion,
  type ContactCompanionDiscountCard,
  type DiscountCardRenewalBookingRequest,
  type SegmentSelectedAdditionalServices,
  type SegmentSelectedPlacements,
  type TripBookingRequest,
  type TripOptionBookingRequest,
} from './generated'
import { addAxiosRequestInterceptors } from './interceptors'
import { type ItinerarySdk } from './itinerary'
import { TrackingSdk } from './tracking'
import { type UserSdk } from './user'

export class BookSdk extends AbstractSdk<BookingControllerApi> {
  readonly #itinerarySdk: ItinerarySdk

  readonly #trackingSdk: TrackingSdk

  constructor(trackingSdk: TrackingSdk, itinerarySdk: ItinerarySdk, ...rest: AbstractSdkConstructorParameters) {
    super(...rest)

    this.#itinerarySdk = itinerarySdk
    this.#trackingSdk = trackingSdk
  }

  static init(
    itinerarySdk: ItinerarySdk,
    configuration: Configuration,
    userSdk: UserSdk,
    authenticationSdkParam?: AuthenticationSdk,
    axiosInstanceParam?: AxiosInstance
  ): BookSdk {
    const axiosInstance = axiosInstanceParam || axios.create()
    const trackingSdk = new TrackingSdk(configuration, userSdk, axiosInstance)

    const authenticationSdk =
      authenticationSdkParam || new AuthenticationSdk(trackingSdk, configuration, userSdk, axiosInstance)

    if (!axiosInstanceParam) {
      addAxiosRequestInterceptors(axiosInstance, userSdk, authenticationSdk)
    }

    return new this(trackingSdk, itinerarySdk, configuration, userSdk, axiosInstance)
  }

  protected getControllerApiConstructor(): ControllerApiConstructor<BookingControllerApi> {
    return BookingControllerApi
  }

  @Catch<AddCompanionFromContactSuccess>()
  async addCompanionFromContact(
    companion: ContactCompanion,
    discountCard?: ContactCompanionDiscountCard
  ): Promise<AddCompanionFromContactSuccess> {
    const request: AddCompanionFromContactRequest = {
      companion,
      discountCard,
    }
    const response = await this.api.addCompanionFromContact(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }

  @Catch<Cart>()
  async book(
    selectedTravelId: string,
    itineraryId?: string,
    selectedPlacements?: SegmentSelectedPlacements,
    segmentSelectedAdditionalServices?: SegmentSelectedAdditionalServices[],
    discountCardPushSelected?: boolean
  ): Promise<Cart> {
    const request: TripBookingRequest = {
      itineraryId: itineraryId || this.#itinerarySdk.itineraryId,
      selectedTravelId,
      discountCardPushSelected: discountCardPushSelected ?? false,
      selectedPlacements,
      segmentSelectedAdditionalServices,
    }
    const response = await this.api.addToCart(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async bookDiscountCard(request: CatalogDiscountCardBookingRequest): Promise<Cart> {
    const response = await this.api.bookDiscountCard(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async bookTerOffer(request: CatalogTerBookingRequest): Promise<Cart> {
    const response = await this.api.addToCartTerOffer(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async delete(travelId: string): Promise<Cart> {
    const response = await this.api.deleteTravel(
      travelId,
      this.userSdk.getBffHeader(),
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }

  @Catch<Cart>()
  async addOptionToCart(name: string, reference: string): Promise<Cart> {
    const optionFinalizationRequest: TripOptionBookingRequest = {
      name,
      reference,
    }
    const response = await this.api.addToCartOption(
      this.userSdk.getBffHeader(),
      optionFinalizationRequest,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async bookDiscountCardForConnectedTraveler(
    connectedTravelerDiscountCardBookingRequest: ConnectedTravelerDiscountCardBookingRequest
  ): Promise<Cart> {
    const response = await this.api.bookDiscountCardForConnectedTraveler(
      this.userSdk.getBffHeader(),
      connectedTravelerDiscountCardBookingRequest,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async getCart(withServices = true): Promise<Cart> {
    const response = await this.api.getCart(
      this.userSdk.getBffHeader(),
      withServices,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async updateCart(
    cart: Cart,
    hasAnInsuranceSelected: boolean,
    toPrereserve: boolean,
    selectedDeliveryModeByGroupId: Record<string, string> = {}
  ): Promise<Cart> {
    const request: CartUpdateRequest = {
      prereserve: toPrereserve,
      insurances: finalizationInsuranceFromTripCart(cart.items, hasAnInsuranceSelected),
      selectedDeliveryModeByGroupId,
      donations: finalizationDonationsFromCart(cart.selectedDonations),
    }
    const response = await this.api.updateCart(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async bookDiscountCardRenewal(request: DiscountCardRenewalBookingRequest): Promise<Cart> {
    const response = await this.api.bookDiscountCardRenewal(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<Cart>()
  async bookTerRenewal(request: CatalogTerRenewalBookingRequest): Promise<Cart> {
    const response = await this.api.bookTerRenewal(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<CartResetTravelAndRenewSearchSuccess>()
  async resetTravelFromCartAndRenewSearch(
    request: CartResetTravelAndRenewSearchRequest
  ): Promise<CartResetTravelAndRenewSearchSuccess> {
    const response = await this.api.resetTravelFromCartAndRenewSearch(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }
}
